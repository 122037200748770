import Pagination from 'rc-pagination/lib/locale/id_ID';
import DatePicker from '../date-picker/locale/id_ID';
import TimePicker from '../time-picker/locale/id_ID';
import Calendar from '../calendar/locale/id_ID';
export default {
    locale: 'id',
    Pagination,
    DatePicker,
    TimePicker,
    Calendar,
    Table: {
        filterTitle: 'Menu filter',
        filterConfirm: 'baik',
        filterReset: 'Setel ulang',
        emptyText: 'Tidak ada data',
        selectAll: 'Pilih halaman saat ini',
        selectInvert: 'Balikkan halaman saat ini',
        sortTitle: 'Menyortir',
    },
    Modal: {
        okText: 'baik',
        cancelText: 'Membatalkan',
        justOkText: 'baik',
    },
    Popconfirm: {
        okText: 'baik',
        cancelText: 'Membatalkan',
    },
    Transfer: {
        titles: ['', ''],
        notFoundContent: 'Tidak ditemukan',
        searchPlaceholder: 'Cari di sini',
        itemUnit: 'barang',
        itemsUnit: 'item',
    },
    Select: {
        notFoundContent: 'Tidak ditemukan',
    },
    Upload: {
        uploading: 'Mengunggah...',
        removeFile: 'Hapus file',
        uploadError: 'Kesalahan pengunggahan',
        previewFile: 'File pratinjau',
    },
};
