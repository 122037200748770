import Pagination from 'rc-pagination/lib/locale/es_ES';
import DatePicker from '../date-picker/locale/es_ES';
import TimePicker from '../time-picker/locale/es_ES';
import Calendar from '../calendar/locale/es_ES';
export default {
    locale: 'es',
    Pagination,
    DatePicker,
    TimePicker,
    Calendar,
    Table: {
        filterTitle: 'Filtrar menú',
        filterConfirm: 'Aceptar',
        filterReset: 'Reiniciar',
        emptyText: 'No hay datos',
        selectAll: 'Seleccionar todo',
        selectInvert: 'Invertir selección',
    },
    Modal: {
        okText: 'Aceptar',
        cancelText: 'Cancelar',
        justOkText: 'Aceptar',
    },
    Popconfirm: {
        okText: 'Aceptar',
        cancelText: 'Cancelar',
    },
    Transfer: {
        notFoundContent: 'No encontrado',
        searchPlaceholder: 'Buscar aquí',
        itemUnit: 'elemento',
        itemsUnit: 'elementos',
    },
    Select: {
        notFoundContent: 'No encontrado',
    },
    Upload: {
        uploading: 'Subiendo...',
        removeFile: 'Eliminar archivo',
        uploadError: 'Error al subir el archivo',
        previewFile: 'Vista previa',
    },
};
